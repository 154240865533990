.contactus-main{
    background-color: black;
    height:auto;
    padding: 0;
}

.img-thumbnail {
    background-color: transparent;
    border: none;
    border-radius: 0!important;
    padding: 0!important;
}

element.style {
    padding: 0rem!important;
}