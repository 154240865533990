.aboutus-main{
    background: #ffffff;
    padding: 0;
}

.ourculture-custom{
    height: auto;
    padding: 1rem;
    color:white;
    font-size: 1rem;
    font-weight: 2rem;
    background: #ee0979;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #ff6a00, #ee0979);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #ff6a00, #ee0979); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.ourservices-custom{
    height: auto;
    margin: 0rem ;
    padding: 1rem;
    color:white;
    background: #00F260;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #0575E6, #00F260);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #0575E6, #00F260); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */    
}

@media (max-width:991px){
    .ourservices-custom{
        margin: 0;
    }
}
